<template>
  <v-dialog
    v-model="show"
    @click:outside="fechar"
    @keydown.esc="fechar"
    width="1100px"
    scrollable
  >
    <v-card outlined>
      <v-card-title class="tocs_gray_1 pa-4">
        <span class="px-2"> Lançamentos finalizados </span>
        <v-spacer></v-spacer>
        <v-btn @click="fechar" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-4 text-subtitle-1">
        <v-data-table
          :headers="headers"
          :items="lancamentos"
          :sort-desc="true"
          :items-per-page="-1"
          hide-default-footer
          class="data-tables data-tables__row-click"
        >
          <template v-slot:item.tipo_operacao="{ item }">
            {{
              item.tipo_operacao === "VENDA"
                ? $tc("global.venda")
                : item.tipo_operacao === "DEVOLUCAO"
                ? $tc("global.devolucao")
                : item.tipo_operacao === "CONTA_RECEBER"
                ? $tc("global.contasReceber")
                : item.tipo_operacao === "CONTA_PAGAR"
                ? $tc("global.contasPagar")
                : item.tipo_operacao === "GASTO"
                ? $tc("global.gasto")
                : ""
            }}
          </template>
          <template v-slot:item.numero="{ item }">
            <span v-if="item.tipo_operacao === 'VENDA' || item.tipo_operacao === 'DEVOLUCAO'">
              {{ item.numero }}
            </span>
          </template>
          <template v-slot:item.valor="{ item }">
            {{ item.moeda_sigla }}
            <span v-if="item.moeda_sigla === 'G$'">
              {{ item.valor | guarani }}
            </span>
            <span v-else>
              {{ item.valor | currency }}
            </span>
          </template>
          <template v-slot:item.pagare="{ item }">
            <v-btn
              v-if="
                item.condicao_pagamento === 2 && item.tipo_operacao === 'VENDA'
              "
              @click.stop="openPagarePdf(item)"
              icon
              small
              color="primary"
            >
              <v-icon>mdi-printer-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.fatura="{ item }">
            <v-btn
              v-if="item.fatura_id"
              @click.stop="
                generateFatura(item.fatura_id, true, false, false, 'fatura')
              "
              icon
              small
              color="primary"
            >
              <v-icon>mdi-printer-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.ticket="{ item }">
            <v-btn
              v-if="item.tipo_operacao === 'VENDA'"
              @click.stop="generateTicket(item.id, false, false)"
              icon
              small
              color="primary"
            >
              <v-icon>mdi-printer-outline</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.recibo="{ item }">
            <v-btn
              v-if="item.recibo_id"
              icon
              small
              color="primary"
              @click.stop="openReciboPdf(item.recibo_id)"
            >
              <v-icon>mdi-printer-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
    <PdfDialogComp
      v-if="pdfDialog"
      :pdfDialog.sync="pdfDialog"
      :pdfData="pdfData"
      title="PDF"
    />
    <DialogAddDocumento
      v-if="dialogAddDocumento"
      :dialogAddDocumento.sync="dialogAddDocumento"
      :cliente_id="venda_final.cliente_id"
      @open-pagare="openPagarePdf"
    />
  </v-dialog>
</template>

<script>
const generatePagare = () =>
  import(
    /* webpackChunkName: "pagaréPdf" */ "@/views/dashboard/caixas-pdv/reports/pagarePdf.js"
  );
const importFatura = () =>
  import(
    /* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_fatura_simples.js"
  );
const importFaturaAutoImpressor = () =>
  import(
    /* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_fatura_simples_auto_impressor.js"
  );
const importTicket = () =>
  import(/* webpackChunkName: "fatura" */ "@/helpers/pdf/pdf_ticket.js");
const generateRecibo = () =>
  import(
    /* webpackChunkName: "reciboPdf" */ "@/components/recibo/reports/reciboPdf.js"
  );
const generateReciboFiscal = () =>
  import(
    /* webpackChunkName: "reciboPdf" */ "@/components/recibo/reports/reciboFiscalPdf.js"
  );
import { fetchOneRecibo } from "@/api/recibos/recibos.js";
import { infoPagareVendaFinalizada } from "@/api/vendas/vendas.js";
import { dataImpressaoFatura } from "@/api/faturas/faturas.js";
import { fetchFatura } from "@/api/faturas/faturas.js";
export default {
  name: "DialogBaixaEmLoteFinalizada",

  components: {
    PdfDialogComp: () => import("@/components/global/PdfDialogComp.vue"),
    DialogAddDocumento: () =>
      import("@/views/dashboard/caixas-pdv/components/DialogAddDocumento.vue"),
  },

  data() {
    return {
      pdfDialog: false,
      pdfData: null,
      dialogAddDocumento: false,
      loading: false,
    };
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    lancamentos: {
      type: Array,
      default: () => [],
    },
    caixa_id: {
      type: Number,
      default: null,
    },
    caixa_af_id: {
      type: Number,
      default: null,
    },
  },

  computed: {
    headers() {
      return [
        {
          text: "Operação",
          value: "tipo_operacao",
        },
        {
          text: "Cliente/Fornecedor",
          value: "cliente_fornecedor_nome",
        },
        {
          text: this.$tc("global.numero"),
          value: "numero",
        },
        {
          text: this.$tc("global.valor"),
          value: "valor",
        },
        {
          text: "Pagaré",
          value: "pagare",
          align: "center",
        },
        {
          text: "Fatura",
          value: "fatura",
          align: "center",
        },
        {
          text: "Ticket",
          value: "ticket",
          align: "center",
        },
        {
          text: "Recibo",
          value: "recibo",
          align: "center",
        },
      ];
    },
  },

  methods: {
    fechar() {
      this.$emit("update:show", false);
    },
    faturar() {
      this.$emit("faturar");
    },
    async openReciboPdf(recibo_af) {
      const recibo = await fetchOneRecibo(recibo_af);

      if (recibo.tipo == "RECEBIMENTO") {
        await generateReciboFiscal().then((module) => {
          let generate = module.generateReciboFiscal;
          generate(recibo_af)
            .then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            })
            .finally(() => {
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        });
      } else {
        await generateRecibo().then((module) => {
          let generate = module.generateRecibo;
          generate(recibo_af)
            .then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            })
            .finally(() => {
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        });
      }
    },
    async generateFatura(fatura_id, print, download, unica, tipo) {
      this.loading = true;

      if (print) {
        dataImpressaoFatura(fatura_id)
          .then(() => {})
          .catch(() => {});
      }

      try {
        let auto_impressor = await this.isAutoImpressor(fatura_id);

        if (auto_impressor) {
          importFaturaAutoImpressor()
            .then((module) => {
              let generate = module.default;
              generate(fatura_id, true, false, false, "fatura").then(
                (response) => {
                  this.pdfData = response;
                  this.pdfDialog = true;
                }
              );
            })
            .finally(() => {
              this.loading_fatura = false;
            });
        } else {
          importFatura()
            .then((module) => {
              let generate = module.default;
              generate(fatura_id, print, download, unica, tipo).then(
                (response) => {
                  this.pdfData = response;
                  this.pdfDialog = true;
                }
              );
            })
            .finally(() => {
              this.loading = false;
            });
        }
      } catch (error) {
        this.loading = false;
        console.log(error);
      }
    },
    async openPagarePdf(item) {
      await infoPagareVendaFinalizada(item.id)
        .then((response) => {
          if (response.status === 200) {
            this.venda_final = response.data.result;
          }
        })
        .catch(() => {});

      if (this.venda_final.cliente_doc_ci || this.venda_final.cliente_doc_ruc) {
        generatePagare().then((module) => {
          let generate = module.generatePagare;
          generate(this.venda_final)
            .then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            })
            .finally(() => {
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        });
      } else {
        this.$toast.error("Cliente não possui documento de identificação");
        this.dialogAddDocumento = true;
      }
    },
    async generateTicket(venda_id, download, print) {
      this.loading = true;
      try {
        importTicket()
          .then((module) => {
            let generate = module.default;
            generate(venda_id, download, print).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading = false;
          });

        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async isAutoImpressor(fatura_id) {
      const fatura = await fetchFatura(fatura_id);
      return fatura.timbrado_auto_impressor;
    },
  },
};
</script>

<style></style>
