var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1100px","scrollable":""},on:{"click:outside":_vm.fechar,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.fechar.apply(null, arguments)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"tocs_gray_1 pa-4"},[_c('span',{staticClass:"px-2"},[_vm._v(" Lançamentos finalizados ")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.fechar}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"pa-4 text-subtitle-1"},[_c('v-data-table',{staticClass:"data-tables data-tables__row-click",attrs:{"headers":_vm.headers,"items":_vm.lancamentos,"sort-desc":true,"items-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.tipo_operacao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.tipo_operacao === "VENDA" ? _vm.$tc("global.venda") : item.tipo_operacao === "DEVOLUCAO" ? _vm.$tc("global.devolucao") : item.tipo_operacao === "CONTA_RECEBER" ? _vm.$tc("global.contasReceber") : item.tipo_operacao === "CONTA_PAGAR" ? _vm.$tc("global.contasPagar") : item.tipo_operacao === "GASTO" ? _vm.$tc("global.gasto") : "")+" ")]}},{key:"item.numero",fn:function(ref){
var item = ref.item;
return [(item.tipo_operacao === 'VENDA' || item.tipo_operacao === 'DEVOLUCAO')?_c('span',[_vm._v(" "+_vm._s(item.numero)+" ")]):_vm._e()]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.moeda_sigla)+" "),(item.moeda_sigla === 'G$')?_c('span',[_vm._v(" "+_vm._s(_vm._f("guarani")(item.valor))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("currency")(item.valor))+" ")])]}},{key:"item.pagare",fn:function(ref){
var item = ref.item;
return [(
              item.condicao_pagamento === 2 && item.tipo_operacao === 'VENDA'
            )?_c('v-btn',{attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openPagarePdf(item)}}},[_c('v-icon',[_vm._v("mdi-printer-outline")])],1):_vm._e()]}},{key:"item.fatura",fn:function(ref){
            var item = ref.item;
return [(item.fatura_id)?_c('v-btn',{attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.generateFatura(item.fatura_id, true, false, false, 'fatura')}}},[_c('v-icon',[_vm._v("mdi-printer-outline")])],1):_vm._e()]}},{key:"item.ticket",fn:function(ref){
            var item = ref.item;
return [(item.tipo_operacao === 'VENDA')?_c('v-btn',{attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.generateTicket(item.id, false, false)}}},[_c('v-icon',[_vm._v("mdi-printer-outline")])],1):_vm._e()]}},{key:"item.recibo",fn:function(ref){
            var item = ref.item;
return [(item.recibo_id)?_c('v-btn',{attrs:{"icon":"","small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.openReciboPdf(item.recibo_id)}}},[_c('v-icon',[_vm._v("mdi-printer-outline")])],1):_vm._e()]}}])})],1),_c('v-divider')],1),(_vm.pdfDialog)?_c('PdfDialogComp',{attrs:{"pdfDialog":_vm.pdfDialog,"pdfData":_vm.pdfData,"title":"PDF"},on:{"update:pdfDialog":function($event){_vm.pdfDialog=$event},"update:pdf-dialog":function($event){_vm.pdfDialog=$event}}}):_vm._e(),(_vm.dialogAddDocumento)?_c('DialogAddDocumento',{attrs:{"dialogAddDocumento":_vm.dialogAddDocumento,"cliente_id":_vm.venda_final.cliente_id},on:{"update:dialogAddDocumento":function($event){_vm.dialogAddDocumento=$event},"update:dialog-add-documento":function($event){_vm.dialogAddDocumento=$event},"open-pagare":_vm.openPagarePdf}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }